import { doRequest } from 'utils/request';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { GET_FEATURE_FLAGS } from 'services/apiUrls';
import * as urls from '../services/apiUrls';
import { useAuth } from './AuthProvider';
import { AxiosResponse } from 'axios';
import { Iany } from 'components/common/types/Shared';

export enum FLAG_IDENTITY {
  JCB_COURIER_FLOW = 'All-CourierFlow',
}

export interface Flag {
  name: FLAG_IDENTITY;
  enabled: boolean;
}

export interface FeatureFlag {
  getFeatureFlag: (flag: FLAG_IDENTITY) => boolean;
}

export const FeatureFlagContext = createContext<FeatureFlag>({
  getFeatureFlag: () => false,
});

export const useFeatureFlag = () => useContext<FeatureFlag>(FeatureFlagContext);

const FeatureFlagProvider: React.FC<{
  children: ReactNode;
}> = (props) => {
  const { isAuthenticated, getMatrixAccessToken } = useAuth();
  const { children } = props;
  const [featureFlagsLoaded, setFeatureFlagsLoaded] = useState<boolean>(false);

  const [flagsData, setFlagsData] = useState<Array<Flag>>([
    {
      name: FLAG_IDENTITY.JCB_COURIER_FLOW,
      enabled: false,
    },
  ]);

  const getFeatureFlagAPI: Iany = async () => {
    return doRequest(urls.GET_FEATURE_FLAGS, 'get');
  };

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      if (getMatrixAccessToken) {
        try {
          const flags: { data: Array<Flag> } = await getFeatureFlagAPI();
          // console.log(flags);
          setFlagsData(flags.data);
          setFeatureFlagsLoaded(true);
        } catch {
          setFeatureFlagsLoaded(true);
        }
      }
    };
    if (isAuthenticated) fetchFeatureFlags();
  }, [isAuthenticated]);

  const getFlagStatus = (flag: FLAG_IDENTITY) => {
    return flagsData?.length > 0
      ? flagsData.find((f) => f.name == flag)?.enabled ?? false
      : false;
  };

  return (
    <FeatureFlagContext.Provider
      value={{
        getFeatureFlag: getFlagStatus,
      }}
    >
      {featureFlagsLoaded ? children : null}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;
